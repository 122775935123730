import { lazy } from 'react'
import type { RouteObject } from 'react-router'

import Loadable from '../components/Loadable'
import Main from '../layouts/Main'

// render - dashboard
const Home = Loadable(lazy(async () => import('../views/Home/Home')))
const Ads = Loadable(lazy(async () => import('../views/Ads/Ads')))
const Data = Loadable(lazy(async () => import('../views/Data/Data')))
const Members = Loadable(lazy(async () => import('../views/Members/Members')))
const OtherTools = Loadable(lazy(async () => import('../views/OtherTools/OtherTools')))
const Tours = Loadable(lazy(async () => import('../views/Tours/Tours')))

// product manager
const ProductManager = Loadable(lazy(async () => import('../views/ProductManager/ProductManager')))
const Discounts = Loadable(lazy(async () => import('../views/ProductManager/Discounts/Discounts')))
const JoinOptions = Loadable(lazy(async () => import('../views/ProductManager/JoinOptions/JoinOptions')))
const Products = Loadable(lazy(async () => import('../views/ProductManager/Products/Products')))

// content manager
const ContentManager = Loadable(lazy(async () => import('../views/ContentManager/ContentManager')))
const Models = Loadable(lazy(async () => import('../views/ContentManager/Models/Models')))
const Categories = Loadable(lazy(async () => import('../views/ContentManager/Categories/Categories')))
const Sets = Loadable(lazy(async () => import('../views/ContentManager/Sets/Sets')))

// administration
const Account = Loadable(lazy(async () => import('../views/Account/Account')))
const Admins = Loadable(lazy(async () => import('../views/Admins/Admins')))
const Roles = Loadable(lazy(async () => import('../views/Roles/Roles')))
const Settings = Loadable(lazy(async () => import('../views/Settings/Settings')))
const PaymentMethods = Loadable(lazy(async () => import('../views/PaymentMethods/PaymentMethods')))
// thirstyx
const Thirstyx = Loadable(lazy(async () => import('../views/Thirstyx/Thirstyx')))

import PrivateWrapper from './PrivateWrapper'

const MainRoutes: RouteObject = {
  path: '/',
  element: <Main />,
  children: [
    {
      path: '*',
      element: (
        <PrivateWrapper>
          <Home />
        </PrivateWrapper>
      )
    },
    {
      path: '/',
      element: (
        <PrivateWrapper>
          <Home />
        </PrivateWrapper>
      )
    },
    {
      path: 'account',
      element: (
        <PrivateWrapper>
          <Account />
        </PrivateWrapper>
      )
    },
    {
      path: 'product-manager',
      element: (
        <PrivateWrapper>
          <ProductManager />
        </PrivateWrapper>
      ),
      children: [
        {
          path: 'discounts',
          element: (
            <PrivateWrapper>
              <Discounts />
            </PrivateWrapper>
          )
        },
        {
          path: 'join-options',
          element: (
            <PrivateWrapper>
              <JoinOptions />
            </PrivateWrapper>
          ),
          children: [{ path: ':joinId/:crudType', element: null }]
        },
        {
          path: 'products',
          element: (
            <PrivateWrapper>
              <Products />
            </PrivateWrapper>
          ),
          children: [{ path: ':productId/:crudType', element: null }]
        }
      ]
    },
    {
      path: 'ads',
      element: (
        <PrivateWrapper>
          <Ads />
        </PrivateWrapper>
      )
    },
    {
      path: 'content-manager',
      element: (
        <PrivateWrapper>
          <ContentManager />
        </PrivateWrapper>
      ),
      children: [
        {
          path: 'categories',
          element: (
            <PrivateWrapper>
              <Categories />
            </PrivateWrapper>
          )
        },
        {
          path: 'models',
          element: (
            <PrivateWrapper>
              <Models />
            </PrivateWrapper>
          )
        },
        {
          path: 'sets',
          element: (
            <PrivateWrapper>
              <Sets />
            </PrivateWrapper>
          )
        }
      ]
    },
    {
      path: 'data',
      element: (
        <PrivateWrapper>
          <Data />
        </PrivateWrapper>
      )
    },
    {
      path: 'members',
      element: (
        <PrivateWrapper>
          <Members />
        </PrivateWrapper>
      ),
      children: [{ path: ':memberId/:crudType', element: null }]
    },
    {
      path: 'other-tools',
      element: (
        <PrivateWrapper>
          <OtherTools />
        </PrivateWrapper>
      )
    },
    {
      path: 'tours',
      element: (
        <PrivateWrapper>
          <Tours />
        </PrivateWrapper>
      )
    },
    {
      path: 'admins',
      element: (
        <PrivateWrapper>
          <Admins />
        </PrivateWrapper>
      ),
      children: [{ path: ':adminId/:crudType', element: null }]
    },
    {
      path: 'roles',
      element: (
        <PrivateWrapper>
          <Roles />
        </PrivateWrapper>
      ),
      children: [{ path: ':roleId/:crudType', element: null }]
    },
    {
      path: 'payment-methods',
      element: (
        <PrivateWrapper>
          <PaymentMethods />
        </PrivateWrapper>
      ),
      children: [{ path: ':methodId/:crudType', element: null }]
    },
    {
      path: 'settings',
      element: (
        <PrivateWrapper>
          <Settings />
        </PrivateWrapper>
      )
    },
    {
      path: 'thirstyx',
      element: (
        <PrivateWrapper>
          <Thirstyx />
        </PrivateWrapper>
      )
    }
  ]
}

export default MainRoutes
