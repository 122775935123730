import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import { LOCAL_STORAGE_TOKEN } from '../../config'
import type { IDoodadResponse } from '../../services/admin'
import { loginUser, logoutUser, userMe } from '../../services/admin'
import { adminService, contentService, memberService, paymentService, productService } from '../../services/https/doodad'

type TUserRole = {
  id: number
  name: string
}
type TUserPermission = {
  id: number
  name: string
}
export interface IReduxUserState {
  isAuthenticated: boolean
  tokenData: {
    accessToken: string
    tokenType: string
    expiresIn: number
  } | null
  userData: {
    name: string
    id: number
    email: string
    emailVerifiedAt: string | null
    createdAt: string
    updatedAt: string
    roles: Array<TUserRole>
    permissions: Array<TUserPermission>
  } | null
}

// initial state
const initialState: IReduxUserState = {
  isAuthenticated: false,
  tokenData: null,
  userData: null
}

export const loginUserThunk = createAsyncThunk<
  IDoodadResponse,
  { email: string; password: string; recaptcha: string; rememberUser: boolean }
>('user/login', async ({ email, password, recaptcha }): Promise<IDoodadResponse> => loginUser(email, password, recaptcha))

export const userMeThunk = createAsyncThunk<IDoodadResponse>('user/me', async (): Promise<IDoodadResponse> => userMe())

export const logoutUserThunk = createAsyncThunk<IDoodadResponse>(
  'user/logout',
  async (): Promise<IDoodadResponse> => logoutUser()
)

const logoutUserAction = (state: IReduxUserState): void => {
  state.isAuthenticated = false
  state.tokenData = null
  state.userData = null
  localStorage.removeItem(LOCAL_STORAGE_TOKEN)
  sessionStorage.removeItem(LOCAL_STORAGE_TOKEN)
  axios.prototype.failedToken = false
  delete adminService.defaults.headers.common.Authorization
  delete contentService.defaults.headers.common.Authorization
  delete memberService.defaults.headers.common.Authorization
  delete productService.defaults.headers.common.Authorization
  delete paymentService.defaults.headers.common.Authorization
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutUserReducer: logoutUserAction
  },
  extraReducers(builder) {
    builder.addCase(loginUserThunk.fulfilled, (state, action) => {
      state.tokenData = { ...action.payload.data }

      if (action.meta.arg.rememberUser) {
        localStorage.setItem(LOCAL_STORAGE_TOKEN, action.payload.data.accessToken)
      } else {
        sessionStorage.setItem(LOCAL_STORAGE_TOKEN, action.payload.data.accessToken)
      }
    })
    builder.addCase(userMeThunk.fulfilled, (state, action) => {
      state.userData = { ...action.payload.data }
      state.isAuthenticated = true
    })
    builder.addCase(logoutUserThunk.fulfilled, logoutUserAction)
  }
})

export default user.reducer

export const { logoutUserReducer } = user.actions
