import { Box, Chip, Typography } from '@mui/material'

const Logo: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
        justifyContent: 'center',
        pl: '5px'
      }}
    >
      <Typography
        sx={{
          fontSize: 20,
          textAlign: 'center',
          color: theme => (theme.palette.mode === 'light' ? '#fff' : theme.palette.text.primary)
        }}
        variant='logovariant'
      >
        Showertime
      </Typography>
      <Chip
        label='v2.0'
        size='small'
        sx={{
          position: 'absolute',
          top: '-5px',
          right: '-35px',
          fontSize: '9px',
          backgroundColor: theme => (theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.primary.main),
          color: '#fff'
        }}
      />
    </Box>
  )
}

export default Logo
