import { LOCAL_STORAGE_KEY } from '../config'

/**
 * Gets snapshot object from localstorage redux state
 * @returns object
 */
export const loadState = (): object | undefined => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    console.error('Error loading state from localStorage:', err)
    return undefined
  }
}

/**
 * Saves a snapshot of redux state to localstorage
 */
export const saveState = (state: object): void => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(LOCAL_STORAGE_KEY, serializedState)
  } catch (err) {
    console.error('Error saving state to localStorage:', err)
  }
}
