import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Box, IconButton, List, Toolbar } from '@mui/material'
import type { DrawerProps } from '@mui/material/Drawer'
import MuiDrawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import Logo from '../../components/common/Logo'
import { drawerWidth } from '../../config'
import type { RootState } from '../../store'
import { toggleDrawer } from '../../store/reducers/menu'
import AccountList from './AccountList'
import MainListItems from './MainListItems'

interface AppDrawerProps extends DrawerProps {
  open?: boolean
}

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })<AppDrawerProps>(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    border: 0,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8)
      }
    })
  }
}))

const MainDrawer: React.FC = () => {
  const dispatch = useDispatch()

  const { drawerOpen } = useSelector((state: RootState) => state.menu)

  const handleDrawerToggle = (): void => {
    dispatch(toggleDrawer())
  }

  return (
    <Drawer
      variant='permanent'
      open={drawerOpen}
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: [1],
          backgroundColor: theme => (theme.palette.mode === 'light' ? theme.palette.primary.main : '')
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}
        >
          <NavLink
            to='/'
            style={{ textDecoration: 'none' }}
          >
            <Logo />
          </NavLink>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <IconButton onClick={handleDrawerToggle}>
            <ChevronLeftIcon sx={{ color: '#fff' }} />
          </IconButton>
        </Box>
      </Toolbar>
      <List
        component='nav'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          flex: 1
        }}
      >
        <MainListItems />
        <AccountList />
      </List>
    </Drawer>
  )
}

export default MainDrawer
