import { configureStore } from '@reduxjs/toolkit'

import { loadState, saveState } from './localStorage'
import reducers from './reducers'

const persistedState = loadState()

const store = configureStore({
  reducer: reducers,
  // setting preloaded state from localstorage snapshot
  preloadedState: persistedState
})

/**
 * store subscriber to localstorage
 */
store.subscribe((): void => {
  const { menu, theme, user } = store.getState()
  saveState({ menu, theme, user })
})

const { dispatch } = store

export { dispatch, store }

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
