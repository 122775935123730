import { lazy } from 'react'
import type { RouteObject } from 'react-router'

import Loadable from '../components/Loadable'
import Minimal from '../layouts/Minimal'

const Login = Loadable(lazy(async () => import('../views/Login/Login')))
const ResetPassword = Loadable(lazy(async () => import('../views/ResetPassword/ResetPassword')))

const LoginRoutes: RouteObject = {
  path: '/',
  element: <Minimal />,
  children: [
    {
      path: 'login',
      element: <Login />
    },
    {
      path: 'reset-password',
      element: <ResetPassword />
    }
  ]
}

export default LoginRoutes
