import { Outlet } from 'react-router-dom'

import MainDrawer from '../core/MainDrawer/MainDrawer'
import MainToolbar from '../core/MainToolbar'
import MainView from '../core/MainView'

const Main: React.FC = () => {
  return (
    <>
      <MainDrawer />
      <MainToolbar />
      <MainView>
        <Outlet />
      </MainView>
    </>
  )
}

export default Main
