/* eslint-disable no-underscore-dangle */
import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'

import { LOCAL_STORAGE_TOKEN } from '../../config'
import { store } from '../../store'
import { logoutUserReducer } from '../../store/reducers/user'
import { refreshToken } from '../admin'

// eslint-disable-next-line import/no-cycle
// import { logoutUser, refreshToken } from '../user.service'

/**
 * creating axios instances for all services
 */
const adminService = axios.create()
const contentService = axios.create()
const memberService = axios.create()
const productService = axios.create()
const paymentService = axios.create()
const nextService = axios.create()

axios.prototype.failedToken = false

/**
 * Error interceptor for axios service
 * @param err any backend error
 * @returns Promise<error>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const interceptorRequestError = async (err: any): Promise<any> => {
  return Promise.reject(err)
}

/**
 * Overides config base URL passed by process env
 * @param cfg AxiosRequestConfig
 * @param baseURL string
 * @returns AxiosRequestConfig
 */
const interceptorRequestCfg = (cfg: InternalAxiosRequestConfig, baseURL?: string): InternalAxiosRequestConfig => {
  const config = { ...cfg }
  config.baseURL = baseURL || process.env.DOODAD_API_CONTENT
  config.headers.Accept = 'application/json'
  const accessToken = localStorage.getItem(LOCAL_STORAGE_TOKEN) || sessionStorage.getItem(LOCAL_STORAGE_TOKEN)
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }
  return config
}

/**
 * interceptor if response failed, handels JWT token refresh
 * @param err any backend error
 * @returns Promise<error>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const interceptorResponseError = async (err: any): Promise<any> => {
  const originalRequest = err.config
  if (err.response.status === 401 && !originalRequest._retry && !axios.prototype.failedToken) {
    if (!originalRequest.headers.Authorization) {
      axios.prototype.failedToken = true
      store.dispatch(logoutUserReducer())
      return Promise.reject(err)
    }
    originalRequest._retry = true
    try {
      toast.warn('Token Expired, refreshing...')
      const { data } = await refreshToken()
      localStorage.setItem(LOCAL_STORAGE_TOKEN, data.accessToken)
      originalRequest.headers.Authorization = `Bearer ${data.accessToken}`
      toast.success(`New Token expires in ${data.expiresIn} seconds`)
      const { data: originalData } = await axios(originalRequest)
      return originalData
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 400) {
        axios.prototype.failedToken = true
        store.dispatch(logoutUserReducer())
        toast.error("Your token can't be refreshed, you need to re-signin")
      }
      return
    }
  }
  return Promise.reject(err)
}

/**
 * Handles response data parsing
 * @param response AxiosResponse
 * @returns AxiosResponse
 */
const interceptorResponse = (response: AxiosResponse): AxiosResponse => response.data

adminService.interceptors.request.use(
  (cfg: InternalAxiosRequestConfig): InternalAxiosRequestConfig =>
    interceptorRequestCfg(cfg, process.env.REACT_APP_DOODAD_API_ADMIN),
  interceptorRequestError
)

contentService.interceptors.request.use(
  (cfg: InternalAxiosRequestConfig): InternalAxiosRequestConfig =>
    interceptorRequestCfg(cfg, process.env.REACT_APP_DOODAD_API_CONTENT),
  interceptorRequestError
)

memberService.interceptors.request.use(
  (cfg: InternalAxiosRequestConfig): InternalAxiosRequestConfig =>
    interceptorRequestCfg(cfg, process.env.REACT_APP_DOODAD_API_MEMBER),
  interceptorRequestError
)

paymentService.interceptors.request.use(
  (cfg: InternalAxiosRequestConfig): InternalAxiosRequestConfig =>
    interceptorRequestCfg(cfg, process.env.REACT_APP_DOODAD_API_PAYMENT),
  interceptorRequestError
)

productService.interceptors.request.use(
  (cfg: InternalAxiosRequestConfig): InternalAxiosRequestConfig =>
    interceptorRequestCfg(cfg, process.env.REACT_APP_DOODAD_API_PRODUCT),
  interceptorRequestError
)

nextService.interceptors.request.use(
  (cfg: InternalAxiosRequestConfig): InternalAxiosRequestConfig => interceptorRequestCfg(cfg, process.env.REACT_APP_NEXT_API_URL),
  interceptorRequestError
)

adminService.interceptors.response.use(interceptorResponse, interceptorResponseError)
contentService.interceptors.response.use(interceptorResponse, interceptorResponseError)
memberService.interceptors.response.use(interceptorResponse, interceptorResponseError)
paymentService.interceptors.response.use(interceptorResponse, interceptorResponseError)
productService.interceptors.response.use(interceptorResponse, interceptorResponseError)
nextService.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => response,
  async err => {
    if (err.response.status === 401) {
      return Promise.reject(err)
    }
    if (err.response.status === 404) {
      return Promise.reject(err)
    }
    return Promise.reject(err)
  }
)

export { adminService, contentService, memberService, nextService, paymentService, productService }
