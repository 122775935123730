import { createSlice } from '@reduxjs/toolkit'

export interface IReduxThemeState {
  mode: 'dark' | 'light'
}

// initial state
const initialState: IReduxThemeState = {
  mode: 'dark'
}

const theme = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleMode(state) {
      state.mode = state.mode === 'dark' ? 'light' : 'dark'
    }
  }
})

export default theme.reducer

export const { toggleMode } = theme.actions
