import 'react-toastify/dist/ReactToastify.css'

import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import AppRoutes from './router'
import { type RootState } from './store'
import { dispatch } from './store'
import { logoutUserReducer, userMeThunk } from './store/reducers/user'
import AppTheme from './theme'

const App: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { mode: paletteMode } = useSelector((state: RootState) => state.theme)

  useEffect(() => {
    if (location.pathname !== '/login' && location.pathname !== '/reset-password') {
      dispatch(userMeThunk()).catch(async () => {
        await dispatch(logoutUserReducer())
        navigate('/login')
      })
    }
  }, [location])

  return (
    <GoogleReCaptchaProvider
      // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA as string}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: 'head',
        nonce: undefined
      }}
      container={{
        parameters: {
          theme: 'dark'
        }
      }}
    >
      <AppTheme>
        <Box sx={{ display: 'flex' }}>
          <AppRoutes />
          <ToastContainer
            position='bottom-right'
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            draggable
            theme={`${paletteMode === 'dark' ? 'dark' : 'light'}`}
          />
        </Box>
      </AppTheme>
    </GoogleReCaptchaProvider>
  )
}

export default App
