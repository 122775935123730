import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import PaymentIcon from '@mui/icons-material/Payment'
import SettingsIcon from '@mui/icons-material/Settings'
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility'
import { Box, Divider, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import ListSubheader from '@mui/material/ListSubheader'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import type { RootState } from '../../store'

const AccountList: React.FC = () => {
  const { pathname } = useLocation()
  const { userData } = useSelector((state: RootState) => state.user)

  const userPermissions = useMemo(() => {
    return userData?.permissions.map(el => el.name) || []
  }, [userData])

  return (
    <Box
      sx={{
        alignItems: 'flex-end'
      }}
    >
      {(userPermissions.includes('View admins') ||
        userPermissions.includes('View roles') ||
        userPermissions.includes('View partners')) && (
        <>
          <Divider sx={{ my: 1 }} />
          <ListSubheader
            component='div'
            inset
          >
            Administration
          </ListSubheader>
          {(userPermissions.includes('View admins') || userPermissions.includes('View partners')) && (
            <ListItemButton
              component={Link}
              selected={pathname === '/admins'}
              to='/admins'
            >
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary='Accounts' />
            </ListItemButton>
          )}
          {userPermissions.includes('View roles') && (
            <ListItemButton
              component={Link}
              selected={pathname === '/roles'}
              to='/roles'
            >
              <ListItemIcon>
                <SettingsAccessibilityIcon />
              </ListItemIcon>
              <ListItemText primary='Roles' />
            </ListItemButton>
          )}
          {userPermissions.includes('View payment methods') && (
            <ListItemButton
              component={Link}
              selected={pathname === '/payment-methods'}
              to='/payment-methods'
            >
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary='Payment Methods' />
            </ListItemButton>
          )}
          <ListItemButton
            component={Link}
            selected={pathname === '/settings'}
            to='/settings'
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary='Settings' />
          </ListItemButton>
        </>
      )}
      <Divider sx={{ my: 1 }} />
      <ListItemButton
        component={Link}
        selected={pathname === '/account'}
        to='/account'
      >
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary='My Account' />
      </ListItemButton>
    </Box>
  )
}

export default AccountList
