import { createSlice } from '@reduxjs/toolkit'

export interface IReduxMenuState {
  drawerOpen: boolean
}

// initial state
const initialState: IReduxMenuState = {
  drawerOpen: true
}

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen
    },
    toggleDrawer(state) {
      state.drawerOpen = !state.drawerOpen
    }
  }
})

export default menu.reducer

export const { openDrawer, toggleDrawer } = menu.actions
