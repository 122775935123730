import { CssBaseline, StyledEngineProvider } from '@mui/material'
import type { ThemeOptions } from '@mui/material/styles'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import type { RootState } from '../store'
import Typography from './typography'

/**
 * Custom MUI Theme provider wrapper component
 * @children ReactNode
 * @returns ThemeProvider
 */
const AppTheme: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { mode } = useSelector((state: RootState) => state.theme)
  const themeTypography = Typography(`'Public Sans', sans-serif`)

  const themeOptions = useMemo<ThemeOptions>(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536
        }
      },
      direction: 'ltr',
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8
        }
      },
      palette: {
        contrastThreshold: 2,
        mode,
        ...(mode === 'light'
          ? {
              primary: {
                light: '#757ce8',
                main: '#2f65cb',
                dark: '#002884',
                contrastText: '#eee'
              },
              secondary: {
                light: '#ff7961',
                main: '#f44336',
                dark: '#ba000d',
                contrastText: '#eee'
              },
              background: {
                default: '#f7f9fc',
                paper: '#fff'
              },
              text: {
                primary: '#212121',
                secondary: '#212121b3',
                icon: '#212121'
              },
              action: {
                selected: '#f7f7f7'
              }
            }
          : {
              primary: {
                main: '#2f65cb',
                contrastText: '#eee'
              },
              success: {
                main: '#2e7d32',
                contrastText: '#eee'
              },
              secondary: {
                main: '#ab47bc',
                contrastText: '#eee'
              },
              warning: {
                main: '#ed6c02',
                contrastText: '#eee'
              },
              info: {
                main: '#0288d1',
                contrastText: '#eee'
              },
              error: {
                main: '#f44336',
                contrastText: '#eee'
              },
              background: {
                default: '#1b2635',
                paper: '#233044'
              },
              text: {
                primary: '#eee',
                secondary: '#eeeeeeb3',
                icon: '#eee'
              },
              action: {
                selected: '#1e293a'
              }
            })
      },
      typography: themeTypography,
      shadows: [
        'none',
        '0 0 0 1px rgba(0 0 0 / 0.1)',
        '0 0 0 1px rgba(0 0 0 / 0.1)',
        '0 0 0 1px rgba(0 0 0 / 0.1)',
        '0 0 0 1px rgba(0 0 0 / 0.1)',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none'
      ]
    }),
    [mode]
  )

  const theme = createTheme(themeOptions)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default AppTheme
