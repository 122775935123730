import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'

import type { RootState } from '../store'

const PrivateWrapper: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.user)

  return isAuthenticated ? (
    children
  ) : (
    <Navigate
      to='/login'
      replace
    />
  )
}
export default PrivateWrapper
