import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Badge, IconButton, Toolbar, Tooltip, Typography } from '@mui/material'
import type { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import MuiAppBar from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { drawerWidth } from '../config'
import { dispatch, type RootState } from '../store'
import { toggleDrawer } from '../store/reducers/menu'
import { toggleMode } from '../store/reducers/theme'
import { logoutUserThunk } from '../store/reducers/user'
interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: 'none',
  backgroundImage: 'none',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.secondary,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const MainToolbar: React.FC = () => {
  const { drawerOpen } = useSelector((state: RootState) => state.menu)
  const { mode: paletteMode } = useSelector((state: RootState) => state.theme)
  const navigate = useNavigate()

  const handleDrawerToggle = (): void => {
    dispatch(toggleDrawer())
  }
  const handleThemeModeChange = (): void => {
    dispatch(toggleMode())
  }

  const logout = async (): Promise<void> => {
    await dispatch(logoutUserThunk())
    toast.success('You have been logged out')
    navigate('/login')
  }

  return (
    <AppBar
      position='absolute'
      open={drawerOpen}
    >
      <Toolbar
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.background.default,
          pr: '24px', // keep right padding when drawer closed,
          color: theme => (theme.palette.mode === 'light' ? '#fff' : theme.palette.text.primary)
        }}
      >
        <IconButton
          edge='start'
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerToggle}
          sx={{
            marginRight: '36px',
            ...(drawerOpen && { display: 'none' })
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component='h1'
          variant='h6'
          color='inherit'
          noWrap
          sx={{ flexGrow: 1 }}
        >
          Dashboard
        </Typography>
        <Tooltip title={`Switch to ${paletteMode === 'dark' ? 'Light' : 'Dark'} theme`}>
          <IconButton
            sx={{ ml: 1 }}
            onClick={handleThemeModeChange}
            color='inherit'
          >
            {paletteMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </Tooltip>
        <Tooltip title='Notifications'>
          <IconButton color='inherit'>
            <Badge
              badgeContent={0}
              color='secondary'
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip title='Logout'>
          <IconButton
            onClick={logout}
            color='inherit'
          >
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  )
}

export default MainToolbar
