/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosResponse } from 'axios'

import { adminService } from './https/doodad'

export interface IDoodadResponse extends AxiosResponse {
  time_until_next_build?: unknown
  can_trigger_build?: unknown
  data: any
  meta: {
    current_page: number
    from: number
    last_page: number
    links: Array<any>
    path: string
    per_page: number
    to: number
    total: number
  }
}

export const loginUser = async (email: string, password: string, recaptcha: string): Promise<IDoodadResponse> =>
  adminService.post('/api/auth/login', {
    email,
    password,
    recaptcha
  })

export const userMe = async (): Promise<IDoodadResponse> => adminService.get('api/auth/me')

export const logoutUser = async (): Promise<IDoodadResponse> => adminService.post('/api/auth/logout')

export const refreshToken = async (): Promise<IDoodadResponse> => adminService.post('/api/auth/refresh')

/**
 *
 * @param page number
 * @param limit number
 * @param roleName string - if parameter not provided, returns all accounts with roles that are not "Partner"
 * @returns Promise
 */
export const getAdminsList = async (page: number, limit: number, roleName: string): Promise<IDoodadResponse> =>
  adminService.get(`/api/admin?limit=${limit}&page=${page}${roleName ? `&roleName=${roleName}` : ''}`)

export const getAdminDetails = async (id: number): Promise<IDoodadResponse> => adminService.get(`/api/admin/${id}`)

export const getPartnerDetails = async (id: number): Promise<IDoodadResponse> => adminService.get(`/api/partner/${id}`)

export const createAdmin = async (name: string, email: string, password: string): Promise<IDoodadResponse> =>
  adminService.post('/api/admin', {
    email,
    name,
    password
  })

export const updateAdmin = async (id: number, name: string): Promise<IDoodadResponse> =>
  adminService.put(`/api/admin/${id}`, {
    name
  })

export const deleteAdmin = async (id: number): Promise<IDoodadResponse> => adminService.delete(`/api/admin/${id}`)

export const changePassword = async (password: string): Promise<IDoodadResponse> =>
  adminService.post('/api/auth/password/new', {
    password
  })

export const resetAdminPassword = async (email: string, recaptcha: string): Promise<IDoodadResponse> =>
  adminService.post('/api/auth/password/forgot', {
    email,
    recaptcha
  })

export const setNewAdminPassword = async (
  token: string,
  password: string,
  email: string,
  recaptcha: string
): Promise<IDoodadResponse> =>
  adminService.post('/api/auth/password/reset', {
    email,
    token,
    password,
    recaptcha
  })
