import { Box, Container, Toolbar } from '@mui/material'
import type { ReactNode } from 'react'

interface IMainViewProps {
  children?: ReactNode
}

const MainView: React.FC<IMainViewProps> = props => {
  const { children } = props
  return (
    <Box
      component='main'
      sx={{
        backgroundColor: theme => theme.palette.background.default,
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto'
      }}
    >
      <Toolbar />
      <Container
        disableGutters
        maxWidth='xl'
        sx={{ mb: 4, p: 4, minWidth: '240px' }}
      >
        {children}
      </Container>
    </Box>
  )
}

export default MainView
