import type { ReactElement } from 'react'
import React, { Suspense } from 'react'

import Loader from './UI/Loader'

/**
 * Views loader
 * @param Component React.FC
 * @returns React.FC
 */
const Loadable =
  (Component: React.FC) =>
  // eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
  (props: any): ReactElement => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  )

export default Loadable
